<template>
  <div class="play-back">
    <div class="play-area">
      <div class="water-mark" v-if="openViewWatermark">
        <OpenData type="userName" :openid="userInfo.realName" />(<OpenData
          type="departmentName"
          :openid="userInfo.departmentName"
        />)
      </div>
      <vueAliplayer
        v-if="state.showPlayer"
        class="video-player"
        ref="aliplayer"
        width="100%"
        height="100%"
        :autoplay="!isSafari"
        :useH5Prism="true"
        x5_type="H5"
        :skinLayout="state.videoPlayer.skinLayout"
        :format="state.videoPlayer.format"
        controlBarVisibility="click"
        @play="playHandler"
        @pause="pauseHandler"
        @requestFullScreen="fullScreenHandler"
        @cancelFullScreen="cancelFullScreenHandler"
        @ended="onPlayerEnded($event)"
        @timeupdate="onPlayerTimeupdate($event)"
        :source="state.filePath"
      >
      </vueAliplayer>
    </div>
    <div class="right-area">
      <div class="title">
        <a-tooltip :title="state.name">{{ state.name }}</a-tooltip>
      </div>
      <div class="document">
        <div
          class="document-item"
          :class="state.currentInfo && state.currentInfo.documentId == item.documentId && 'active'"
          v-for="(item, index) in state.playBackList"
          :key="item.documentId"
          @click="getDetail(item.documentId)"
          :title="item.fileName"
        >{{ index + 1 }}、{{ item.fileName }}</div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { reactive, ref, getCurrentInstance, computed, onUnmounted, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import vueAliplayer from "@/components/course/vue-aliplayer";
import OpenData from "@/components/OpenData.vue";
import { getPlayBackList, getPlayBackDetail, startLearnByPlayBack, learningByPlayBack } from "@/api/live";

const { t: $t } = useI18n();
const store = useStore();
const route = useRoute();
const router = useRouter();
const { proxy } = getCurrentInstance();
const companyInfo = computed(() => store.getters.companyInfo);
const state = reactive({
  taskId: parseInt(route.query.taskId || 0),
  detailId: parseInt(route.query.detailId || 0),
  name: route.query.name,
  playBackList: [],
  currentInfo: null,
  isPause: false, // 是否暂停
  showPlayer: false,
  videoPlayer: {
    format: "mp4",
    skinLayout: [
      { name: "bigPlayButton", align: "blabs", x: 30, y: 80 },
      {
        name: "controlBar",
        align: "blabs",
        x: 0,
        y: 0,
        children: [
          { name: "progress", align: "blabs", x: 0, y: 44 },
          { name: "playButton", align: "tl", x: 15, y: 12 },
          { name: "timeDisplay", align: "tl", x: 10, y: 7 },
          {
            name: "fullScreenButton",
            align: "tr",
            x: 10,
            y: 12,
          },
          { name: "setting", align: "tr", x: 15, y: 12 },
          { name: "volume", align: "tr", x: 5, y: 10 },
        ],
      },
    ],
  },
  filePath: '',
  loadingPlayer: false,
  openViewWatermark: true,
  timer: null,
  remainStudyTime: 0,
  currentTime: 0,
  setTimeFlag: 1,
  computedPlayNum: true,
});
state.openViewWatermark = companyInfo.value.isOpenViewWatermark === 1;

async function getList() {
  const res = await getPlayBackList(state.taskId, state.detailId);
  if (res.ret === 0) {
    state.playBackList = res.data || [];
    state.playBackList.length && getDetail(state.playBackList[0].documentId)
  } else {
    proxy.$message.error(res.msg);
  }
}
async function getDetail(docId) {
  if (state.currentInfo && state.currentInfo.documentId === docId) {
    return false;
  }
  const res = await getPlayBackDetail(state.taskId, state.detailId, docId);
  if (res.ret === 0) {
    state.currentInfo = res.data;
    // 剩余时长 = 总时长 - 已完成时长
    state.remainStudyTime = Math.ceil(
      state.currentInfo.times -
        (state.currentInfo.times * state.currentInfo.progress) / 100
    );
    state.setTimeFlag = 1;
    state.computedPlayNum = true;
    playDetail();
  } else {
    proxy.$message.error(res.msg);
  }
}
const aliplayer = ref(null);
let isSafari = /Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent);
function playDetail(){
  state.showPlayer = true;
  if (isSafari) {
    state.filePath =
      state.currentInfo.filePath.slice(
        0,
        state.currentInfo.filePath.lastIndexOf(".")
      ) + "/1/mp4/1_1.mp4";
  } else {
    state.videoPlayer.format = "m3u8";
    state.filePath =
      state.currentInfo.filePath.slice(
        0,
        state.currentInfo.filePath.lastIndexOf(".")
      ) + "/1/hls/index.m3u8";
  }
  var el =
    document.getElementsByTagName("video")[0] ||
    document.getElementsByTagName("audio")[0];
  if (el) {
    //重新创建播放器
    aliplayer.value.scriptTagStatus = 2;
    aliplayer.value.initAliplayer();
  }
};
async function start() {
  let params = {
    taskId: state.taskId,
    detailId: state.detailId,
    sectionId: state.currentInfo.documentId,
    location: parseInt(state.currentInfo.location),
  };
  const res = await startLearnByPlayBack(params);
  if (res.ret === 0) {};
};
async function learning() {
  let params = {
    taskId: state.taskId,
    detailId: state.detailId,
    sectionId: state.currentInfo.documentId,
    location: parseInt(state.currentInfo.location),
  };
  const res = await learningByPlayBack(params);
  if (res.ret === 0) {};
};
function playHandler() {
  // 是否计算播放次数
  if (state.computedPlayNum) {
    state.computedPlayNum = false;
    start();
  }
  startInterval();
};
function pauseHandler() {
  state.isPause = true;
};
function onPlayerEnded() {
  learning();
};
function onPlayerTimeupdate() {
  if (aliplayer.value == null) {
    return false;
  }
  //从上次播放的位置开始播放音频
  if (state.setTimeFlag == 1) {
    let getDuration = aliplayer.value.getDuration() || 0; //获取视频总时长
    let cktime = Math.floor(state.currentInfo.location); //获取视频最后播放位置
    // 跳转到某个时刻进行播放
    if (cktime > 0 && cktime != Math.floor(getDuration)) {
      aliplayer.value.seek(cktime);
    }
    state.setTimeFlag = 2;
  }
  state.currentInfo.location = aliplayer.value.getCurrentTime();
};
function startInterval() {
  clearInterval(state.timer);
  state.timer = setInterval(() => {
    if (state.isPause) {
      return false;
    }
    state.remainStudyTime -= 1;
    state.currentTime += 1;
    //每10秒提交一次 || 剩余学习时间 == 0
    if (state.currentTime % 10 == 0 || state.remainStudyTime == 0) {
      console.log("提交");
      learning();
    }
  }, 1000);
};
function fullScreenHandler() {
  let water = document.getElementsByClassName("water-mark")[0];
  let wrap = document.getElementsByClassName("prism-player")[0];
  let water2 = water.cloneNode(true);
  wrap.appendChild(water2);
};
function cancelFullScreenHandler() {};
onMounted(() => {
  getList();
});
onUnmounted(() => {
  if (state.timer) {
    // 清除定时器
    clearInterval(state.timer);
    state.timer = null;
    learning();
  }
});
</script>
<style lang="less" scoped>
.play-back {
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
  .play-area {
    width: calc(100% - 350px);
    height: 100vh;
    text-align: center;
    position: relative;
    background-color: #000;
    .video-player {
      width: 100%;
      height: 100%;
    }
    .video-player {
      ::v-deep(.prism-player) {
        background: url("~@/assets/image/audio-cover.gif") no-repeat;
        background-size: 100% 100%;
      }
    }
  }
  .right-area {
    width: 350px;
    height: 100vh;
    overflow-y: auto;
    background-color: #fff;
    box-shadow: -2px 0 8px #00000026;
    .title {
      padding: 20px;
      font-size: 16px;
      font-weight: 600;
      color: #333;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      border-bottom: 1px solid #f0f0f0;
    }
    .document {
      .document-item {
        line-height: 28px;
        padding: 10px 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        border-bottom: 1px solid #f0f0f0;
        cursor: pointer;
        &:hover {
          color: var(--theme);
        }
      }
      .active {
        color: var(--theme);
      }
    }
  }
}
.water-mark {
  pointer-events: none;
  z-index: 999;
  position: absolute;
  right: 20px;
  top: 20px;
  font-size: 24px;
  color: rgba(170, 170, 170, 0.4);
  line-height: 40px;
}
.water-marks {
  pointer-events: none;
  z-index: 999;
  position: absolute;
  right: 0;
  left: 0;
  top: 34px;
  bottom: 0;
  overflow: hidden;
  .item {
    float: left;
    transform: rotate(-20deg);
    margin: 80px 50px 250px 50px;
    font-size: 18px;
    line-height: 40px;
    color: rgba(170, 170, 170, 0.2);
    pointer-events: none;
  }
}
</style>
